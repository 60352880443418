.ql-container {
    border-radius: 0px 0px 8px 8px !important;
    border: 1px solid #ccc !important;
    
  }
  .ql-editor {
    border-radius: 8px !important;
  }
  .ql-toolbar.ql-snow {
    border-radius: 8px 08px 0px 0px !important;
  }
  .ql-editor.ql-blank::before {
    font-style:normal;
  }